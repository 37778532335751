<template>
  <div>
    <Menu :menu="accessMenu" prefix="" />
    <transition name="page">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Menu from '@/components/atoms/Menu.vue'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu: [
        {
          title: 'Категории',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PARTNERS.CATEGORIES.INDEX,
        },
        {
          title: 'Партнёры',
          link: ADDWINE_ADMIN_ROUTES.CONTENT.PARTNERS.PARTNERS.INDEX,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      filterAccessMenuList: 'filterAccessMenuList',
    }),
    accessMenu() {
      return this.filterAccessMenuList(this.menu)
    },
  },
}
</script>
